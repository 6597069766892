// src/analytics.js
export const gtagPageView = (path) => {
    if (typeof window.gtag !== 'function') {
      console.warn('Google Analytics not initialized');
      return;
    }
    window.gtag('config', 'G-NKPETDMGDP', {
      page_path: path,
    });
  };
  